import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
  CidadeParams,
  formMasterDetailEditType,
  PedidoVendaContextData,
} from './types';
import {
  addInsert,
  deleteItens,
  insertOrUpdate,
  MasterDetailProps,
} from '~/utils/masterDetail';
import { nanoid } from 'nanoid';
import { LojaContext } from '~/context/loja';
import api from '~/services/api';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaFinanceiro, schemaPedido, schemaProduto } from './validations';
import { toast } from 'react-toastify';
import { moneyFormat, transformAsCurrency } from '~/utils/functions';
import { GridRowParams } from '@material-ui/data-grid';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';
import { format } from 'date-fns';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { CalcDataCondicao } from '../DevolucaoESaidaNFDeFornecedor/functions/Procedures/CalcDataCondicao';
import moment from 'moment';
import PedidoVendaPDF from './classes/PedidoDeVendaPDF';
import PedidoVendaBobinaPDF from './classes/PedidoDeVendaBobinaPDF';

export const PedidoVendaContext = createContext({} as PedidoVendaContextData);

interface PedidoVendaContextProviderProps {
  children: ReactNode;
}

export function PedidoVendaContextProvider({
  children,
}: PedidoVendaContextProviderProps): JSX.Element {
  const MySwal = withReactContent(Swal);
  const { codLoja, loja } = useContext(LojaContext);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [isCancelada, setIsCancelada] = useState<boolean>(false);
  const [isDisabledPedido, setIsDisabledPedido] = useState<boolean>(false);
  const [isTransmitir, setIsTransmitir] = useState<boolean>(false);

  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<string>('pedido');
  const [lojaProduto, setLojaProduto] = useState<number>(loja.cod_loja);

  // Variável para calcular valor total em Itens
  const [valorTotal, setValorTotal] = useState('0');
  const [valorTotalIsNegative, setValorTotalIsNegative] = useState(false);

  // Variável para calcular valor total Financeiro
  const [totalFinanceiro, setTotalFinanceiro] = useState(0);

  // Variável para popular select de Cidades fora do próprio Select
  const [cidade, setCidade] = useState<CidadeParams>();

  /**
   * Form Pedido Venda
   */
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    setFocus,
    clearErrors,
    formState,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schemaPedido),
    reValidateMode: 'onBlur',
  });

  /**
   * Form Produto
   */
  const {
    register: produtoRegister,
    handleSubmit: produtoHandleSubmit,
    control: produtoControl,
    setValue: produtoSetValue,
    getValues: produtoGetValues,
    setError: produtoSetError,
    setFocus: produtoSetFocus,
    clearErrors: produtoClearErrors,
    formState: produtoFormState,
    watch: produtoWatch,
    reset: produtoReset,
  } = useForm({
    resolver: yupResolver(schemaProduto),
    reValidateMode: 'onBlur',
  });

  /**
   * Form Financeiro
   */
  const {
    register: financeiroRegister,
    handleSubmit: financeiroHandleSubmit,
    control: financeiroControl,
    setValue: financeiroSetValue,
    getValues: financeiroGetValues,
    setError: financeiroSetError,
    setFocus: financeiroSetFocus,
    clearErrors: financeiroClearErrors,
    formState: financeiroFormState,
    watch: financeiroWatch,
    reset: financeiroReset,
  } = useForm({
    resolver: yupResolver(schemaFinanceiro),
    reValidateMode: 'onBlur',
  });

  /**
   * MasterDetail Functions
   */
  const masterDetailDefault = [
    {
      obj_name: 'produtos',
      pk_fields: ['cod_seq_produto'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
    {
      obj_name: 'financeiro',
      pk_fields: ['cod_seq_parcela'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
  ];

  const [masterDetail, setMasterDetail] =
    useState<MasterDetailProps[]>(masterDetailDefault);
  const [produtosRows, setProdutosRows] = useState<any[]>([]);
  const [isFormProdutoEditing, setIsFormProdutoEditing] =
    useState<formMasterDetailEditType>({
      isEdit: false,
      uuid: undefined,
    });
  const [parcelasRows, setParcelasRows] = useState<any[]>([]);
  const [isFormFinanceiroEditing, setIsFormFinanceiroEditing] =
    useState<formMasterDetailEditType>({
      isEdit: false,
      uuid: undefined,
    });

  /**
   * USEEFFECTS
   */

  useEffect(() => {
    const totalSum = produtosRows.reduce((acc, produto) => {
      return acc + transformAsCurrency(produto.val_total || 0); // Garante que valores undefined sejam tratados como 0
    }, 0);

    setValorTotal(moneyFormat(String(totalSum), 2));
  }, [produtosRows]);

  const handleValidation = useCallback(async () => {
    const cod_pessoa = getValues('busca_parceiro')?.value;
    const dataEmissao = getValues('dta_emissao');
    const cod_perfil = getValues('cod_perfil');

    if (cod_perfil === undefined || cod_perfil === '') {
      toast.warning('Perfil deve ser selecionado.');
      return true;
    }
    if (cod_pessoa === undefined || cod_pessoa === '') {
      toast.warning('Cliente deve ser selecionado.');
      return true;
    }
    if (dataEmissao === '' || dataEmissao === undefined) {
      toast.warning('Data de Emissão deve ser informada.');
      return true;
    }
    if (produtosRows.length === 0) {
      toast.warning('Item(s) do Pedido deve(m) ser informado(s).');
      return true;
    }
    return false;
  }, [getValues, produtosRows]);
  const handleAddProduto = produtoHandleSubmit(async (produto) => {
    const { busca_parceiro, cod_perfil } = getValues();
    if (
      produto.produtos.flg_ipv === false &&
      !Number.isInteger(Number(produto.qtd_pedido))
    ) {
      toast.warn('Quantidade Total não pode ser fracionada.');
      return;
    }
    const clienteSelecionado = busca_parceiro?.value;
    const perfilSelecionado = cod_perfil?.value;

    if (!clienteSelecionado && !perfilSelecionado) {
      return toast.warning('Perfil e Cliente devem ser selecionados.');
    }
    if (!clienteSelecionado) {
      return toast.warning('Cliente deve ser selecionado.');
    }
    if (!perfilSelecionado) {
      return toast.warning('Perfil deve ser selecionado.');
    }
    const maxNumItem = produtosRows.reduce(
      (max: any, item: any) => Math.max(max, item.num_item || 0),
      0,
    );

    const uuid = isFormProdutoEditing.isEdit
      ? isFormProdutoEditing.uuid
      : nanoid();

    const num_item = isFormProdutoEditing.isEdit
      ? produtosRows.find(
          (produtoRow) => produtoRow.uuid === isFormProdutoEditing.uuid,
        )?.num_item
      : maxNumItem + 1;
    const formatedProduto: any = {
      ...produto,
      val_desconto: produto.val_desconto ? produto.val_desconto : '0,00',
      acrescimo: produto.acrescimo ? produto.acrescimo : '0,00',
      qtd_embalagem:
        produto.produtos.qtd_embalagem_venda || produto.qtd_embalagem_venda,
      des_unidade: produto.produtos.des_unidade_venda || produto.des_unidade,
      cod_produto: produto.produtos.value,
      des_produto: produto.produtos.label,
      uuid,
      id: uuid,
      num_item,
    };
    const produtoExists = produtosRows.find(
      (produtoRow) =>
        produtoRow.cod_produto === produto.produtos.cod_produto &&
        produtoRow.uuid !== isFormProdutoEditing.uuid,
    );
    if (produtoExists) {
      const confirmaSubstituicao = await MySwal.fire({
        title: 'Item já existente na tabela',
        text: 'Deseja substituir?',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
      if (confirmaSubstituicao) {
        formatedProduto.uuid = produtoExists.uuid;
        formatedProduto.id = produtoExists.id;
        formatedProduto.num_item = produtoExists.num_item;
        const produtosDetail: any[] = await insertOrUpdate(
          'produtos',
          formatedProduto,
          masterDetail,
          setMasterDetail,
        );
        setProdutosRows(produtosDetail);
      }
      resetFormProdutoData();
      setIsFormProdutoEditing({ isEdit: false, uuid: undefined });
      return;
    }
    const produtosDetail: any[] = await insertOrUpdate(
      'produtos',
      formatedProduto,
      masterDetail,
      setMasterDetail,
    );
    setProdutosRows(produtosDetail);
    resetFormProdutoData();
    setIsFormProdutoEditing({ isEdit: false, uuid: undefined });
  });
  const handleDeleteProduto = useCallback(
    async (row: any) => {
      // Verifica se o produto a ser deletado está sendo editado
      if (isFormProdutoEditing.uuid === row.uuid) {
        const confirmaRemocao = await MySwal.fire({
          title: 'O item encontra-se em edição, deseja realmente remove-lo?',
          text: row.des_produto,
          showCancelButton: true,
          confirmButtonColor: '#8850BF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result: any) => {
          if (result.isConfirmed) {
            return true;
          }
          return false;
        });
        if (confirmaRemocao) {
          const produtosDetail: any[] = await deleteItens(
            'produtos',
            row.uuid,
            masterDetail,
            setMasterDetail,
          );
          setProdutosRows(produtosDetail);
          produtoReset();
          setIsFormProdutoEditing({ isEdit: false, uuid: undefined });
        }
        return;
      }

      // Confirma remoção do item da tabela
      const confirmaRemocao = await MySwal.fire({
        title: 'Deseja remover o item?',
        text: row.des_produto,
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
      if (confirmaRemocao) {
        const produtosDetail: any[] = await deleteItens(
          'produtos',
          row.uuid,
          masterDetail,
          setMasterDetail,
        );
        setProdutosRows(produtosDetail);
      }
    },
    [isFormProdutoEditing.uuid, produtoReset, setProdutosRows],
  );

  const handleSearchEndereco = useCallback(
    async (cod_pessoa: number) => {
      try {
        const { data } = await api.get(`/pessoas/cod_pessoa/${cod_pessoa}`);
        if (data.success && data.data && data.data.length > 0) {
          const pessoa = data.data[0];
          // Preenchendo endereço de entrega
          setValue('num_cep', pessoa.num_cep);
          setValue('des_logradouro', pessoa.des_logradouro);
          setValue('num_endereco', pessoa.num_endereco);
          setValue('des_bairro', pessoa.des_bairro);
          setValue('des_complemento', pessoa.des_complemento);
          setCidade({
            cidade: pessoa.des_cidade && pessoa.des_cidade.toUpperCase(),
            uf: pessoa.des_uf ? pessoa.des_uf : pessoa.des_sigla,
          });
        }
      } catch (error: any) {
        if (error.data && error.data.message) {
          toast.error(error.data.message);
          return;
        }
        toast.error(String(error));
      }
    },
    [getValues],
  );

  const removeParcelasRecalculo = () => {
    const parcelasFind = masterDetail.find(
      (item) => item.obj_name === 'financeiro',
    );
    parcelasFind?.itens.insert.forEach((parcela) => {
      deleteItens('financeiro', parcela.uuid, masterDetail, setMasterDetail);
    });
  };
  const handleRecalculaFinanceiro = async () => {
    const isInValid = await handleValidation();
    if (isInValid) return;
    const cod_pessoa = getValues('busca_parceiro')?.value;
    const dta_emissao = getValues('dta_emissao');
    const dta_saida = getValues('dta_saida');
    let realizaBuscaParcelas = true;
    if (parcelasRows.length !== 0) {
      await MySwal.fire({
        title:
          'Deseja pesquisar novamente as condições de pagamento deste cliente/perfil?',
        text: 'As parcelas atuais poderão sofrer alteração de prazos, formas de pagamento e valores.',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          realizaBuscaParcelas = true;
        } else {
          realizaBuscaParcelas = false;
        }
      });
    }
    if (realizaBuscaParcelas) {
      try {
        const res = await api.get(
          `/emissao-nfe/financeiro/fornecedor/${cod_pessoa}`,
        );
        const { data, success } = res.data;

        if (success) {
          if (data.length <= 0) {
            const {
              cod_perfil: { cod_cc, cod_categoria, cod_perfil, value },
            } = getValues();
            const apiResponse = await api.get(`/busca-parcelas/${value}`);
            const { data: dataResponse } = apiResponse.data;
            if (dataResponse.length > 0) {
              const valParcela =
                transformAsCurrency(valorTotal || 0) / dataResponse.length;
              const options = dataResponse.map(
                async (item: any, index: number) => {
                  const dtavencimento = CalcDataCondicao(
                    null, // cod_cc,
                    dta_emissao,
                    dta_emissao,
                    item.num_condicao,
                    0,
                  );
                  const dta_vencimento = moment(dtavencimento, 'YYYY-MM-DD');
                  const uuid = nanoid();

                  const calculadoParcela = {
                    uuid,
                    id: uuid,
                    num_registro: index + 1,
                    num_condicao: item.num_condicao,
                    cod_condicao: item.cod_condicao,
                    des_condicao: item.des_condicao,
                    val_parcela: transformAsCurrency(
                      Number(valParcela.toFixed(2)),
                    ),
                    cod_finalizadora: item.cod_finalizadora,
                    des_finalizadora: item.des_finalizadora,
                    cod_cc,
                    cod_categoria,
                    dta_vencimento,
                    flg_quitado: false,
                  };

                  const parcelasDetail: any[] = await insertOrUpdate(
                    'financeiro',
                    calculadoParcela,
                    masterDetail,
                    setMasterDetail,
                  );
                  setParcelasRows(parcelasDetail);
                },
              );
              return;
            }
            Swal.fire({
              text: `Nenhuma condição de pagamento localizada para esse parceiro ou perfil.`,
              icon: 'info',
              showConfirmButton: true,
              confirmButtonText: 'OK',
            });
            return;
          }

          const { cod_cc, cod_categoria } = getValues('cod_perfil');
          const valParcela =
            data.length <= 0
              ? 0
              : transformAsCurrency(valorTotal) / data.length;

          removeParcelasRecalculo();
          data.map(async (item: any, index: number) => {
            const dtavencimento = CalcDataCondicao(
              cod_cc,
              dta_emissao,
              dta_emissao,
              item.num_condicao,
              0,
            );
            const uuid = nanoid();

            const calculadoParcela = {
              uuid,
              id: uuid,
              num_registro: index + 1,
              num_condicao: item.num_condicao,
              cod_condicao: item.cod_condicao,
              des_condicao: item.des_condicao,
              val_parcela: transformAsCurrency(Number(valParcela.toFixed(2))),
              cod_finalizadora: item.cod_finalizadora,
              des_finalizadora: item.des_finalizadora,
              cod_cc,
              cod_categoria,
              dta_vencimento: `${format(
                new Date(dtavencimento),
                'yyyy-MM-dd',
              )}`,
              flg_quitado: false,
            };

            const parcelasDetail: any[] = await insertOrUpdate(
              'financeiro',
              calculadoParcela,
              masterDetail,
              setMasterDetail,
            );
            setParcelasRows(parcelasDetail);
          });
        }
      } catch (error: any) {
        if (error.data && error.data.message) {
          toast.error(error.data.message);
          return;
        }
        toast.error(String(error));
      }
    }
  };

  const handleCancelarPedidoVenda = async () => {
    const { tipo_status, tipo_negociacao, cod_seq_pedido } = getValues();

    if (tipo_status === 2) {
      return toast.warning(
        'Pedido já transmitido, use a tela de Cancelamento de Nota Fiscal!',
      );
    }

    if (tipo_status === 3) {
      return toast.warning('Pedido já cancelado!');
    }

    if (!(tipo_status === 0 && [0, 1].includes(tipo_negociacao.value))) {
      return toast.warning(
        'Só é possível cancelar pedidos pendentes com tipo de negociação "Orçamento" ou "Pedido".',
      );
    }

    await MySwal.fire({
      title: ``,
      text: 'Deseja Realmente Cancelar? ',

      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await api.put(
            `/pedido-venda-status/${cod_seq_pedido}`,
            { tipo_status: 3 },
          );
          if (data.success) {
            resetFormData();
            setShowSearch(true);
            return toast.success('Pedido cancelado com sucesso!');
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const handleTransmitirPedidoVenda = async () => {
    const { tipo_status, tipo_negociacao, cod_seq_pedido, cod_loja } =
      getValues();
    try {
      const { data } = await api.post(`/pedido-venda-transmitir`, {
        cod_loja,
        cod_seq_pedido,
      });
      if (data.success) {
        return toast.success('Pedido transmitido com sucesso!');
      }
      if (!data.success) {
        Swal.fire({
          text: 'Para emissão de NFCe, o certificado digital precisa ser parametrizado.\n Vá a tela de “Parametrização de NFe“, carregue um certificado digital válido e volte aqui para tentar novamente',
          icon: 'warning',
          width: 700,
          showConfirmButton: true,
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetFormProdutoData = () => {
    produtoClearErrors();
    produtoSetValue('produtos', '');
    produtoSetValue('qtd_pedido', '');
    produtoSetValue('val_pedido', '');
    produtoSetValue('val_desconto', '');
    produtoSetValue('acrescimo', '');
  };
  const resetFormData = useCallback(() => {
    setMasterDetail(masterDetailDefault);
    setProdutosRows([]);
    setParcelasRows([]);
    reset({
      busca_parceiro: { label: 'Selecione', value: null },
      cod_perfil: { label: 'Selecione', value: null },
      vendedor: { label: 'Selecione', value: null },
      tipo_negociacao: { label: 'Selecione', value: null },
      dta_emissao: '',
      num_cep: '',
      des_logradouro: '',
      num_endereco: '',
      des_bairro: '',
      des_complemento: '',
      dta_entrega: '',
    });
    setCidade(undefined);
    produtoReset();
    financeiroReset({
      num_condicao: 30,
      condicao: {
        value: 2,
        label: 'DD - DIAS DA DATA',
        cod_condicao: 2,
        des_condicao: 'DIAS DA DATA',
        des_definicao: 'DD',
      },
      finalizadora: '',
      dta_vencimento: '',
      val_financeiro: '',
    });
    setValue('cod_loja', loja.cod_loja);
    setValue('tipo_negociacao', {
      value: 0,
      label: 'Orçamento',
    });
    setIsFormFinanceiroEditing({ isEdit: false, uuid: undefined });
    setIsFormProdutoEditing({ isEdit: false, uuid: undefined });
    setCurrentTab('pedido');
    setIsUpdate(false);
    setIsDisabledPedido(false);
    setIsCancelada(false);
    setIsTransmitir(false);
  }, [masterDetail, loja, clearErrors, setValue]);

  const handleFormatPedido = (pedido: any) => {
    return {
      formPedido: {
        cod_seq_pedido: isUpdate ? pedido.cod_seq_pedido : null,
        cod_loja: pedido.cod_loja,
        cod_pessoa: pedido.busca_parceiro.value,
        cod_perfil: pedido.cod_perfil.value,
        dta_emissao: pedido.dta_emissao,
        tipo_negociacao: pedido.tipo_negociacao.value,
        tipo_pedido: 0, // 0 - Venda
        tipo_status: isUpdate ? pedido.tipo_status : null,
        cod_usuario_vendedor: pedido.vendedor.cod_usuario,
        // Entrega
        des_logradouro: pedido.des_logradouro,
        num_endereco: pedido.num_endereco,
        num_cep: pedido.num_cep,
        des_bairro: pedido.des_bairro,
        des_complemento: pedido.des_complemento,
        cod_cidade: pedido.cidades.value,
        dta_entrega: pedido.dta_entrega === '' ? null : pedido.dta_entrega,
      },

      itens: handleFormatProdutos(produtosRows),
      financeiro: handleFormatParcelas(parcelasRows),
    };
  };
  const handleFormatProdutos = (produtos: any) => {
    const newProdutosRows = produtosRows.map((produto) => {
      return {
        num_item: produto.num_item,
        cod_gtin: produto.cod_gtin || produto.produtos.cod_gtin_principal,
        cod_produto: produto.cod_produto,
        des_unidade: produto.des_unidade,
        qtd_embalagem: produto.qtd_embalagem,
        val_pedido: produto.val_pedido
          ? transformAsCurrency(produto.val_pedido || 0)
          : 0,
        val_desconto: produto.val_desconto
          ? transformAsCurrency(produto.val_desconto || 0)
          : 0,
        val_acrescimo: produto.acrescimo
          ? transformAsCurrency(produto.acrescimo || 0)
          : 0,
        qtd_pedido: produto.qtd_pedido,
      };
    });
    return newProdutosRows;
  };
  const handleFormatParcelas = (parcelas: any) => {
    const newParcelasRows = parcelasRows.map((parcela) => {
      return {
        cod_finalizadora: parcela.cod_finalizadora,
        cod_condicao: parcela.cod_condicao,
        num_condicao: parcela.num_condicao,
        val_parcela: parcela.val_parcela,
        dta_vencimento: parcela.dta_vencimento,
      };
    });
    return newParcelasRows;
  };
  const onSave = handleSubmit(async (formPedido) => {
    const pedidoObject = handleFormatPedido(formPedido);
    if (produtosRows.length === 0) {
      return toast.warning('Pelo menos um item deve ser informado.');
    }
    if (
      formPedido.cod_perfil.flg_gera_financeiro &&
      parcelasRows.length === 0
    ) {
      return toast.warning(
        'Pelo menos uma parcela financeira deve ser informada.',
      );
    }
    if (formPedido.tipo_negociacao.value === 2) {
      return toast.warning(
        'Tipo “PDV Online“ não disponível para lançamento por essa tela. Selecione entre Orçamento ou Pedido',
      );
    }

    const totalParcelas = parcelasRows.reduce(
      (acc, parcela) => acc + parcela.val_parcela,
      0,
    );
    if (
      formPedido.cod_perfil.flg_gera_financeiro &&
      transformAsCurrency(totalParcelas).toFixed(2) !==
        transformAsCurrency(valorTotal).toFixed(2)
    ) {
      toast.warning(
        `Total das parcelas difere do total da NF. \n A soma dos valores das parcelas deve ser igual ao total da nota.`,
      );
      return;
    }

    if (!isUpdate) {
      const flg_shouldContinue = await shouldContinue(
        formPedido.vendedor.des_usuario,
        formPedido.busca_parceiro.nome_pessoa,
        valorTotal,
      );
      if (!flg_shouldContinue) {
        return;
      }
    }

    try {
      if (isUpdate) {
        const { data } = await api.put(
          `/pedido-venda/${formPedido.cod_seq_pedido}`,
          pedidoObject,
        );
        if (data.success) {
          resetFormData();
          setShowSearch(true);
          return toast.success(data.message);
        }
      }

      const { data } = await api.post('/pedido-venda', pedidoObject);
      if (data.success) {
        resetFormData();
        return toast.success(data.message);
      }
    } catch (error: any) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
        return;
      }
      toast.error(String(error));
    }
  });

  const getItens = async (codPedido: string) => {
    try {
      const { data } = await api.get(`/itens-pedido-venda/${codPedido}`);

      if (data.success) {
        const produtosRowsObj = data.data.map((produto: any) => {
          const uuid = nanoid();
          const val_unitario = produto.val_pedido / produto.qtd_pedido;
          const val_pedido = produto.val_pedido * produto.qtd_pedido;
          const val_desconto = produto.val_desconto * produto.qtd_pedido;
          const val_acrescimo = produto.val_acrescimo * produto.qtd_pedido;
          const val_total = val_pedido - val_desconto + val_acrescimo;
          produto.uuid = uuid;
          produto.id = uuid;
          produto.val_total = moneyFormat(String(val_total));
          produto.val_pedido = moneyFormat(String(produto.val_pedido));
          produto.val_desconto = moneyFormat(produto.val_desconto);
          produto.acrescimo = moneyFormat(String(produto.val_acrescimo));
          return produto;
        });
        const newProdutosRows = await addInsert(
          'produtos',
          produtosRowsObj,
          masterDetail,
          setMasterDetail,
        );

        setProdutosRows(newProdutosRows || []);
      }
    } catch (error: any) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
        return;
      }
      toast.error(String(error));
    }
  };

  const getParcelas = async (codPedido: string) => {
    try {
      const { data } = await api.get(`/parcelas-pedido-venda/${codPedido}`);

      if (data.success) {
        const parcelasRowsObj = data.data.map((parcela: any) => {
          const uuid = nanoid();
          parcela.uuid = uuid;
          parcela.id = uuid;
          return parcela;
        });
        const newParcelasRows = await addInsert(
          'financeiro',
          parcelasRowsObj,
          masterDetail,
          setMasterDetail,
        );

        setParcelasRows(newParcelasRows || []);
      }
    } catch (error: any) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
        return;
      }
      toast.error(String(error));
    }
  };

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;

    // Salvando num_pedido e status e cod_seq_pedido
    setValue('cod_seq_pedido', row.cod_seq_pedido);
    setValue('tipo_status', row.tipo_status);
    setValue('num_pedido', row.num_pedido);
    setValue('des_tipo_status', row.des_tipo_status);

    // validação para botão transmitir
    if (row.tipo_negociacao === 2 && row.tipo_status === 0) {
      setIsTransmitir(true);
    }

    // validação para bloqueio dos campos
    // Se TIPO_NEGOCIACAO === 2
    if (row.tipo_negociacao === 2) {
      setIsDisabledPedido(true);
    }
    // Se TIPO_NEGOCIACAO === 1 E TIPO_STATUS === 2 --TOTAL
    if (row.tipo_negociacao === 1 && row.tipo_status === 2) {
      setIsDisabledPedido(true);
    }

    // Se TIPO_STATUS === 3 -- CANCELADO
    if (row.tipo_status === 3) {
      setIsCancelada(true);
      setIsDisabledPedido(true);
    }

    const cidadeParceiro = row.des_cidade
      ? ` (${row.des_cidade} - ${row.des_uf})`
      : '';
    const buscaParceiroValues = {
      nome_pessoa: row.nome_pessoa,
      des_cidade: row.des_cidade,
      des_uf: row.des_uf,
      value: row.cod_pessoa,
      label: row.cod_pessoa
        ? `${row.cod_pessoa} - ${row.nome_pessoa?.trim()} ${cidadeParceiro}`
        : 'Nenhum cliente selecionado',
    };

    // Preenchimento de componentes com informações do ROW
    setLojaProduto(row.cod_loja);
    setValue('cod_loja', row.cod_loja);
    setValue('busca_parceiro', buscaParceiroValues);
    setValue('cod_perfil', {
      value: row.cod_perfil,
      label: row.cod_perfil
        ? `${row.cod_perfil} - ${row.des_perfil}`
        : 'Nenhum perfil selecionado',
      flg_gera_financeiro: row.flg_gera_financeiro || false,
    });
    setValue('vendedor', {
      value: row.cod_usuario_vendedor,
      label: row.cod_usuario_vendedor
        ? `${row.cod_usuario_vendedor} - ${row.des_vendedor}`
        : 'Nenhum vendedor selecionado',
    });
    setValue('tipo_negociacao', {
      value: row.tipo_negociacao,
      label: row.des_tipo_negociacao,
    });
    setValue(
      'dta_emissao',
      row.dta_emissao
        ? format(createDateWithoutTimezone(row.dta_emissao), 'yyyy-MM-dd')
        : '',
    );

    // Preenchimento de Entrega
    setValue('num_cep', row.num_cep);
    setValue('des_logradouro', row.des_logradouro);
    setValue('num_endereco', row.num_endereco);
    setValue('des_bairro', row.des_bairro);
    setValue('des_complemento', row.des_complemento);
    setCidade({
      cidade: row.entrega_cidade && row.entrega_cidade.toUpperCase(),
      uf: row.entrega_sigla,
    });
    setValue(
      'dta_entrega',
      row.dta_entrega
        ? format(createDateWithoutTimezone(row.dta_entrega), 'yyyy-MM-dd')
        : '',
    );
    await getItens(row.cod_seq_pedido);
    await getParcelas(row.cod_seq_pedido);

    setIsUpdate(true);
    setShowSearch(false);
  };
  const onNewData = () => {
    resetFormData();
    setShowSearch(false);
    setIsUpdate(false);
  };

  const handlePrint = async (option: string | undefined) => {
    let pdf;

    const { cod_seq_pedido, cod_loja, tipo_negociacao, tipo_status } =
      getValues();
    try {
      if (option === 'danfe') {
        // Validação
        if (tipo_negociacao.value !== 2) {
          toast.warning(
            'Danfe disponível apenas para pedidos trasmitidos pelo PDV!',
          );
          return false;
        }
        if (tipo_status >= 3) {
          toast.warning('Venda cancelada, Danfe não pode ser impressa!');
          return false;
        }
        if (tipo_negociacao.value === 2 && tipo_status < 2) {
          toast.warning(
            'Pedido não transmitido, realize a transmissão para imprimir a Danfe!',
          );
          return false;
        }

        const { data } = await api.post(`/pedido-venda-danfe/${cod_loja}`, {
          cod_seq_pedido,
        });

        if (data.success) {
          const byteArray = new Uint8Array(data.data.data);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          const fileURL = URL.createObjectURL(blob);
          const pdfWindow = window.open();
          if (pdfWindow) pdfWindow.location.href = fileURL;
          return true;
        }
      }

      const { data } = await api.get(
        `/imprimir-pedido-venda/${cod_seq_pedido}`,
      );

      if (data.success) {
        if (option === 'A4') {
          pdf = new PedidoVendaPDF(data.data[0]);
        } else {
          pdf = new PedidoVendaBobinaPDF(data.data[0]);
        }
        pdf.generatePDF();

        return true;
      }
    } catch (error: any) {
      return false;
    }
    return false;
  };
  // Modal de confirmação
  const shouldContinue = async (vendedor: any, cliente: any, total: any) => {
    const confirmAlert = await MySwal.fire({
      title: `Confirma a gravação de Pedido?`,
      html: `<div style='text-align: left; margin-bottom: -16px'><strong>Vendedor:</strong> ${vendedor}</div></br>
      <div style='text-align: left; margin-bottom: -16px'><strong>Cliente:</strong> ${cliente}</div><br>
      <div style='text-align: left; margin-bottom: -16px'><strong>Valor Total:</strong> R$ ${total}</div><br>`,
      text: '',
      showCloseButton: true,
      confirmButtonColor: '#0065FF',
      cancelButtonColor: '#ccc',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        return true;
      }
      return false;
    });
    return confirmAlert;
  };
  return (
    <PedidoVendaContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        showSearch,
        setShowSearch,
        cidade,
        setCidade,
        formPedidoVenda: {
          register,
          handleSubmit,
          control,
          setValue,
          getValues,
          setError,
          setFocus,
          clearErrors,
          formState,
          watch,
          reset,
        },
        formProduto: {
          register: produtoRegister,
          handleSubmit: produtoHandleSubmit,
          control: produtoControl,
          setValue: produtoSetValue,
          getValues: produtoGetValues,
          setError: produtoSetError,
          setFocus: produtoSetFocus,
          clearErrors: produtoClearErrors,
          formState: produtoFormState,
          watch: produtoWatch,
          reset: produtoReset,
        },
        formFinanceiro: {
          register: financeiroRegister,
          handleSubmit: financeiroHandleSubmit,
          control: financeiroControl,
          setValue: financeiroSetValue,
          getValues: financeiroGetValues,
          setError: financeiroSetError,
          setFocus: financeiroSetFocus,
          clearErrors: financeiroClearErrors,
          formState: financeiroFormState,
          watch: financeiroWatch,
          reset: financeiroReset,
        },

        lojaProduto,
        setLojaProduto,
        isUpdate,
        isCancelada,
        isDisabledPedido,
        isTransmitir,
        setIsUpdate,
        resetFormData,
        resetFormProdutoData,
        onSave,
        onRowClick,
        onNewData,
        valorTotal,
        valorTotalIsNegative,

        // masterDetail
        masterDetail,
        setMasterDetail,
        produtosRows,
        parcelasRows,
        isFormFinanceiroEditing,
        setIsFormFinanceiroEditing,
        isFormProdutoEditing,
        setIsFormProdutoEditing,
        setParcelasRows,

        // Functions
        handleAddProduto,
        handleDeleteProduto,
        handleSearchEndereco,
        handleRecalculaFinanceiro,
        handleCancelarPedidoVenda,
        handlePrint,
        handleTransmitirPedidoVenda,
      }}
    >
      {children}
    </PedidoVendaContext.Provider>
  );
}

export const usePedidoVenda = (): PedidoVendaContextData => {
  return useContext(PedidoVendaContext);
};
